<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>LINE 應用中心</b-breadcrumb-item>
            <b-breadcrumb-item active>LINE 聊天</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-name"
      title="變更顯示名稱"
      ok-title="儲存"
      cancel-title="取消"
      centered
    >
      <b-input
        type="text"
        class="form-control"
        maxlength="20"
        placeholder="變更顯示名稱"
      />
    </b-modal>

    <b-modal
      id="modal-tags"
      title="編輯標籤"
      ok-title="儲存"
      cancel-title="取消"
      centered
    >
      <div class="border rounded p-1">
        <b-badge v-for="i in tags" :key="i" variant="dark" class="m-1">
          {{ i }}
          <button class="btn btn-xs p-0">
            <i class="fa fa-remove text-white"></i>
          </button>
        </b-badge>
        <b-input
          type="text"
          class="border-0"
          maxlength="20"
          placeholder="輸入標籤"
        />
      </div>

      <div class="h6 mt-3">現有標籤</div>
      <b-button
        v-for="i in tags"
        :key="i"
        variant="secondary"
        class="m-1 text-dark"
        size="xs"
      >
        {{ i }}
      </b-button>
    </b-modal>

    <b-modal
      id="modal-note"
      title="新增記事本"
      ok-title="儲存"
      cancel-title="取消"
      centered
    >
      <b-textarea
        class="form-control"
        maxlength="300"
        placeholder="你可於此新增用戶的相關資訊，且記事本中的資訊不會向對方公開"
        rows="10"
      />
    </b-modal>

    <b-nav tabs class="border-bottom-0">
      <b-nav-item active>OA 訊息</b-nav-item>
      <b-nav-item>聊天室</b-nav-item>
    </b-nav>
    <b-card no-body class="chatroom d-flex flex-row">
      <div
        ref="chatlist"
        class="chat-list position-relative d-flex flex-column border-right"
      >
        <div class="header px-3 pt-3 pb-2 border-bottom">
          <div class="search mb-2">
            <i class="fa fa-search" aria-hidden="true"></i>
            <b-form-input
              type="search"
              v-model.trim="keyword"
              placeholder="姓名搜尋"
            ></b-form-input>
          </div>

          <div class="row">
            <b-dropdown
              :variant="!currentRoomType ? 'light' : currentRoomType.variant"
              class="filter col-6"
              ref="roomTypeFilter"
            >
              <template #button-content>
                <div class="flex-fill">
                  {{ !currentRoomType ? "對話類型" : currentRoomType.text }}
                </div>
              </template>
              <div
                class="item py-1 px-3"
                v-for="i in roomTypeFilters"
                :key="i.value"
                @click="singleFilterSelect(i)"
              >
                <span class="d-flex justify-content-between align-items-center">
                  <i
                    :class="`fa fa-check ${
                      currentRoomType != i && 'text-white'
                    } mr-3`"
                    aria-hidden="true"
                  ></i>

                  <span
                    :class="`mb-0 px-3 py-2 badge badge-${i.variant} flex-fill`"
                    >{{ i.text }}</span
                  >
                </span>
              </div>
            </b-dropdown>

            <b-dropdown class="filter col-6" ref="roomStatusFilter">
              <template #button-content>
                <div class="flex-fill">處理狀態</div>
              </template>

              <div class="search mb-2 px-2">
                <i class="fa fa-search" aria-hidden="true"></i>
                <b-form-input
                  type="search"
                  v-model.trim="roomStatusKeyword"
                  placeholder="搜尋"
                ></b-form-input>
              </div>

              <div
                v-for="i in roomStatusFilters.filter((x) => {
                  return roomStatusKeyword == null || roomStatusKeyword == ''
                    ? true
                    : x.text.indexOf(roomStatusKeyword) >= 0;
                })"
                :key="i.value"
                @click="multiFilterSelect(i)"
                class="item py-1 px-3"
              >
                <span class="d-flex justify-content-between align-items-center">
                  <i
                    :class="`fa ${
                      currentRoomStatus.includes(i)
                        ? 'fa-check-square-o'
                        : 'fa-square-o'
                    } mr-3`"
                    aria-hidden="true"
                  ></i>
                  <span
                    :class="`mb-0 px-3 py-2 badge badge-${i.variant} flex-fill`"
                    >{{ i.text }}</span
                  >
                </span>
              </div>
            </b-dropdown>
          </div>
        </div>

        <b-list-group class="list list-group-flush">
          <template v-if="customers.length > 0">
            <b-list-group-item
              v-for="i in customers"
              :key="i.id"
              class="d-flex align-items-center border-0"
              role="button"
              :variant="chatroomId == i.id ? 'secondary' : 'default'"
              @click="
                () => {
                  chatroomId = i.id;
                }
              "
            >
              <b-avatar class="mr-3" :src="i.avatar_url"></b-avatar>
              <div class="mr-auto font-weight-bold text-nowrap">
                {{ i.name }}
                <div class="text-muted small">
                  {{ i.message }}
                </div>
                <b-badge pill v-if="i.status" :variant="i.status.variant">
                  {{ i.status.text }}</b-badge
                >
              </div>

              <div class="text-muted small text-center align-self-start">
                <div class="mb-1">{{ formatDate(i.updated_at) }}</div>
                <b-badge v-if="i.unread > 0" pill variant="primary">{{
                  i.unread
                }}</b-badge>
              </div>
            </b-list-group-item>
          </template>
          <b-list-group-item v-else class="empty">
            <!-- <img src="./images/empty.svg" /> -->
            <div class="mt-3 text-dark small">目前沒有名單</div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="resizer" @mousedown="resizeStart" @mouseup="resizeEnd">
        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      </div>

      <div class="chatbox flex-fill d-flex flex-column border-right">
        <div class="header">
          <div
            class="
              d-flex
              justify-content-between
              px-3
              py-2
              border-bottom
              flex-wrap
            "
          >
            <div class="font-weight-bold">
              <b-avatar class="mr-3">
                <img :src="customers[0].avatar_url" class="img-fluid" />
              </b-avatar>
              {{ customers[0].name }}
            </div>

            <div class="d-flex">
              <b-button
                class="m-1"
                @click="
                  () => {
                    let msg = Object.assign(messages[1]);
                    messages = [msg].concat(messages);
                    unread = true;
                  }
                "
              >
                Test Message</b-button
              >
              <b-button variant="outline-success" class="m-1">
                <i class="fa fa-comments-o" aria-hidden="true"></i>
                待處理</b-button
              >
              <b-button variant="outline-dark" class="m-1">
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                處理完畢</b-button
              >
              <b-button
                variant="outline-dark"
                class="m-1"
                @click="
                  () => {
                    isMessageSearching = !isMessageSearching;
                  }
                "
              >
                <i class="fa fa-search" aria-hidden="true"></i>
                搜尋</b-button
              >

              <b-dropdown variant="outline-dark" class="m-1">
                <template #button-content>
                  <i class="fa fa-sign-in m-0 mr-1" aria-hidden="true"></i>
                  指派他人
                </template>
                <b-dropdown-item href="#">
                  <b-avatar size="2rem"></b-avatar>
                  黃智彬
                </b-dropdown-item>
                <b-dropdown-item href="#">
                  <b-avatar size="2rem"></b-avatar>
                  黃智彬
                </b-dropdown-item>
                <b-dropdown-item href="#">
                  <b-avatar size="2rem"></b-avatar>
                  黃智彬
                </b-dropdown-item>
              </b-dropdown>

              <b-button variant="outline-dark" class="m-1">
                <i class="fa fa-check m-0 text-success" aria-hidden="true"></i>
                完成對話
              </b-button>
            </div>
          </div>

          <div
            v-if="isMessageSearching"
            class="search-message p-2 border-bottom d-flex"
          >
            <b-form-input
              size="sm"
              type="search"
              class="flex-fill"
              placeholder="輸入搜尋內容"
            />
            <b-button
              class="btn btn-light ml-2"
              @click="
                () => {
                  isMessageSearching = !isMessageSearching;
                }
              "
              ><i class="fa fa-remove"></i
            ></b-button>
          </div>
        </div>

        <div
          ref="messages"
          class="message-container d-flex flex-column-reverse flex-fill px-3"
        >
          <div
            :class="[
              'message-box',
              'my-2',
              message.customer_id == 29 && 'owner',
            ]"
            :key="idx"
            v-for="(message, idx) in messages"
          >
            <div
              class="strike small text-muted pb-2"
              v-if="checkStrike(messages, idx)"
            >
              {{ formatDate(messages[idx].created_at) }}
            </div>
            <div class="message-wrapper">
              <div class="d-flex align-items-start">
                <b-avatar
                  v-if="message.customer_id != 29"
                  class="mr-2"
                  :src="
                    message.customer.data
                      ? message.customer.data.pictureUrl ||
                        message.customer.avatar_url
                      : message.customer.avatar_url
                  "
                ></b-avatar>
                <div>
                  <small v-if="message.customer_id != 29">{{
                    message.customer.name
                  }}</small>
                  <div class="message py-2 px-3 mt-1">
                    {{ message.content }}
                  </div>
                </div>
              </div>
              <div class="small text-muted text-right mt-1">
                <svg
                  v-if="message.customer_id == 29"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  height="12"
                  fill="#1e88e5"
                >
                  <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z"
                  />
                </svg>
                {{ format(new Date(message.created_at), "HH:mm") }}
              </div>
            </div>
          </div>
          <div class="text-center">
            <b-spinner
              v-if="loading"
              variant="secondary"
              small
              class="position-absolute"
            ></b-spinner>
          </div>
        </div>
        <div class="border-top position-relative">
          <div
            class="position-absolute unread d-flex justify-content-between px-3"
          >
            <span></span>
            <b-button
              v-if="unread"
              variant="dark"
              size="sm"
              pill
              @click="scrollToBottom"
            >
              新訊息
            </b-button>

            <b-button
              v-if="!bottom"
              variant="dark"
              size="sm"
              class="px-2"
              pill
              @click="scrollToBottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                height="16"
                fill="white"
              >
                <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
                />
              </svg>
            </b-button>
          </div>

          <ChatBox
            canAttach
            canSticker
            canImage
            v-model="message"
            @insertSticker="insertSticker"
            @insertImage="insertImage"
            @insertFile="insertFile"
          />
        </div>
      </div>
      <div class="profile">
        <div class="text-center p-3">
          <b-avatar
            class="my-4"
            size="13rem"
            :src="customers[0].avatar_url"
          ></b-avatar>
          <div class="h4 p-2">
            {{ customers[0].name }}
            <button class="btn h4" v-b-modal.modal-name>
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tags">
            <b-badge v-for="i in tags" :key="i" variant="dark" class="m-1">{{
              i
            }}</b-badge>
            <button class="btn" v-b-modal.modal-tags>
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <b-list-group
          id="accordionExample"
          flush
          class="border-top border-bottom accordion"
        >
          <b-list-group-item
            role="button"
            class="d-flex justify-content-between align-items-center"
            v-b-modal.modal-note
          >
            記事本
            <i class="fa fa-plus"></i>
          </b-list-group-item>
          <b-list-group-item role="button">
            <div
              v-b-toggle.collapse-profile
              class="d-flex justify-content-between align-items-center"
            >
              基本資料
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
            <b-collapse id="collapse-profile" class="py-3">
              <div class="form-group">
                <label for="InputPhone">行動電話</label>
                <input
                  type="tel"
                  class="form-control"
                  id="InputPhone"
                  placeholder="請使用 0-9,+,-組合的電話號碼格式"
                />
              </div>

              <div class="form-group">
                <label for="InputEmail">電子信箱</label>
                <input
                  type="email"
                  class="form-control"
                  id="InputEmail"
                  placeholder="請輸入電子信箱"
                />
              </div>

              <div class="form-group">
                <label for="InputBirthday">生日</label>
                <input
                  type="date"
                  class="form-control"
                  id="InputBirthday"
                  placeholder="日期格式為西元年月日，如 1990-01-01"
                />
              </div>

              <div class="form-group">
                <label for="InputGender">性別</label>
                <input
                  type="text"
                  class="form-control"
                  id="InputGender"
                  placeholder="性別"
                />
              </div>

              <div class="form-group">
                <label for="InputLanguage">語言</label>
                <input
                  type="text"
                  class="form-control"
                  id="InputLanguage"
                  placeholder="語言"
                />
              </div>

              <div class="form-group">
                <label for="InputCountry">國家</label>
                <input
                  type="text"
                  class="form-control"
                  id="InputCountry"
                  placeholder="國家"
                />
              </div>

              <div class="form-group">
                <label for="InputCity">現居城市</label>
                <input
                  type="text"
                  class="form-control"
                  id="InputCity"
                  placeholder="現居城市"
                />
              </div>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-card>
  </div>
</template>

<script>
import "bootstrap-vue/dist/bootstrap-vue.css";
import format from "date-fns/format";
import ChatBox from "../../../components/Page/Chatroom/ChatBox.vue";

export default {
  data() {
    return {
      chatroomId: null,
      customers: [
        {
          id: 48,
          name: "Paddy \u9ec3\u667a\u5f6c",
          avatar_url:
            "https://sprofile.line-scdn.net/0hdYLu4-hBO3ZhDBPdzLFFCRFcOBxCfWJkSjpxFlReNhVebn8oGG90EgcPZUdbPX8iSz8hRwMOYxNtH0wQf1rHQmY8ZUFYO34mRWh1lQ",
          updated_at: "2022-02-11T07:00:18.000000Z",
          message: "thx",
          status: { text: "待處理", variant: "outline-success" },
          unread: 3,
        },
        {
          id: 29,
          name: "\u6e2c\u8a66\u4eba1 \ud83c\udf92",
          avatar_url: null,
          updated_at: "2022-02-11T07:00:15.000000Z",
          message: "CCC",
          status: { text: "處理完畢", variant: "outline-primary" },
          unread: 2,
        },
        {
          id: 743,
          name: "\u66f8\u5305\ud83c\udf92",
          avatar_url: null,
          updated_at: "2022-03-16T09:01:03.000000Z",
          message: "test",
        },
        {
          id: 731,
          name: "\u66f8\u5305\ud83c\udf92",
          avatar_url:
            "https://profile.line-scdn.net/0m039bf1157251701adb649c8d5a7caf2daacadbb4af8d",
          updated_at: "2022-03-08T14:14:02.000000Z",
          message: "你好阿",
        },
      ],
      messages: [
        {
          id: 175,
          chatroom_id: 3,
          customer_id: 29,
          content: "here",
          created_at: "2022-04-14T02:53:12.000000Z",
          updated_at: "2022-04-14T02:53:12.000000Z",
          customer: {
            id: 29,
            org_id: 2,
            user_id: null,
            merchant_id: 3,
            name: "isacl \u674e\u5ba5\u8fb0",
            outer_code: "U086cba565044f91b35b33753490841c2",
            bundle_code: null,
            bundle_code_type: null,
            mobile_phone: null,
            phone_verified: false,
            email: null,
            avatar_url:
              "https://profile.line-scdn.net/0m0e1306967251eb182b939a007f8d56ca2f208ec5d3c0",
            birthday: null,
            sexual: null,
            data: null,
            is_friend: false,
            join_datetime: null,
            leave_datetime: "2022-02-11T07:00:15.000000Z",
            created_at: "2021-12-06T06:41:27.000000Z",
            updated_at: "2022-02-11T07:00:15.000000Z",
            creator_type: null,
            creator_id: null,
          },
        },
        {
          id: 170,
          chatroom_id: 3,
          customer_id: 100,
          content: "\u54bf\u54bf\u5440\u5440",
          created_at: "2022-04-14T02:46:58.000000Z",
          updated_at: "2022-04-14T02:46:58.000000Z",
          customer: {
            id: 100,
            org_id: 2,
            user_id: null,
            merchant_id: 3,
            name: "Albert Chen",
            outer_code: "U81001d751f13982d784532dc4aab323e",
            bundle_code: null,
            bundle_code_type: null,
            mobile_phone: null,
            phone_verified: false,
            email: null,
            avatar_url:
              "https://sprofile.line-scdn.net/0h7kjiZ0qkaERHEEDELNUWOzdAay5kYTFWP3QhInESZHYoci8abncuKnUYNyd5cHoXPH4lI3ZEY3FLAx8iWUaUcEAgNnN-Jy0UY3Qmpw",
            birthday: null,
            sexual: null,
            data: null,
            is_friend: false,
            join_datetime: "2021-11-14T12:03:06.000000Z",
            leave_datetime: "2022-02-11T07:00:15.000000Z",
            created_at: "2021-11-04T14:51:27.000000Z",
            updated_at: "2022-03-21T17:58:10.000000Z",
            creator_type: null,
            creator_id: null,
          },
        },
        {
          id: 141,
          chatroom_id: 3,
          customer_id: 50,
          content: "\u5594\u5594\u5594\u5594\u5594\u5594",
          created_at: "2022-04-14T02:29:24.000000Z",
          updated_at: "2022-04-14T02:29:24.000000Z",
          customer: {
            id: 50,
            org_id: 2,
            user_id: null,
            merchant_id: 3,
            name: "Min",
            outer_code: "U8d0f1f5851761e2d7233593f6437ad0b",
            bundle_code: null,
            bundle_code_type: null,
            mobile_phone: null,
            phone_verified: false,
            email: null,
            avatar_url:
              "https://sprofile.line-scdn.net/0haDjKoaoRPmtHMina-jZAFDdiPQFkQ2d5PFMlCHUyMFx8Ai4-blR4XXs0ZlN_An46aFZ4DXdlaFNLIUkNWWTCX0ACYFx-BXs7Y1ZwiA",
            birthday: null,
            sexual: null,
            data: {
              userId: "U8d0f1f5851761e2d7233593f6437ad0b",
              displayName: "\u8b1d\u5927\u9eb5 Min",
              pictureUrl:
                "https://sprofile.line-scdn.net/0haDjKoaoRPmtHMina-jZAFDdiPQFkQ2d5PFMlCHUyMFx8Ai4-blR4XXs0ZlN_An46aFZ4DXdlaFNLIUkNWWTCX0ACYFx-BXs7Y1ZwiA",
              language: "zh-Hant",
            },
            is_friend: true,
            join_datetime: null,
            leave_datetime: null,
            created_at: null,
            updated_at: "2022-03-21T17:58:10.000000Z",
            creator_type: null,
            creator_id: null,
          },
        },
        {
          id: 118,
          chatroom_id: 3,
          customer_id: 743,
          content: "123",
          created_at: "2022-03-17T14:09:30.000000Z",
          updated_at: "2022-03-17T14:09:30.000000Z",
          customer: {
            id: 743,
            org_id: 2,
            user_id: null,
            merchant_id: 3,
            name: "\u66f8\u5305\ud83c\udf92",
            outer_code: "Uc64bf7304bc42efc90e530ff401e5076",
            bundle_code: null,
            bundle_code_type: null,
            mobile_phone: null,
            phone_verified: false,
            email: null,
            avatar_url: null,
            birthday: null,
            sexual: null,
            data: {
              userId: "Uc64bf7304bc42efc90e530ff401e5076",
              displayName: "\u66f8\u5305\ud83c\udf92",
              statusMessage:
                "\u2b06\u2b06\u2b07\u2b07\u2b05\u27a1\u2b05\u27a1\ud83c\udd71\ud83c\udd70\ud83c\udd97",
              pictureUrl:
                "https://profile.line-scdn.net/0m039bf1157251701adb649c8d5a7caf2daacadbb4af8d",
            },
            is_friend: false,
            join_datetime: null,
            leave_datetime: null,
            created_at: "2022-03-16T09:01:03.000000Z",
            updated_at: "2022-03-16T09:01:03.000000Z",
            creator_type: null,
            creator_id: null,
          },
        },
        {
          id: 45,
          chatroom_id: 3,
          customer_id: 29,
          content: "\u4e00\u9ede\u90fd\u4e0d\u5947\u602a",
          created_at: "2022-03-16T10:01:53.000000Z",
          updated_at: "2022-03-16T10:01:53.000000Z",
          customer: {
            id: 29,
            org_id: 2,
            user_id: null,
            merchant_id: 3,
            name: "\u6e2c\u8a66\u4eba1 \ud83c\udf92",
            outer_code: "XXXXXXCCC",
            bundle_code: null,
            bundle_code_type: null,
            mobile_phone: null,
            phone_verified: false,
            email: null,
            avatar_url: null,
            birthday: null,
            sexual: null,
            data: {
              userId: "U6154977cfd6c097a3de815cf72c51b6b",
              displayName: "Paddy \u9ec3\u667a\u5f6c",
              pictureUrl:
                "https://profile.line-scdn.net/0hdYLu4sqSO3ZxDBPdzLFEIU1JNRsGIj0-CW0kFVIMNxUPPi4jRT9xEFZfZkZdO30jT2whRQNbZ0AJ",
            },
            is_friend: false,
            join_datetime: null,
            leave_datetime: "2022-02-11T07:00:15.000000Z",
            created_at: "2021-08-27T10:09:30.000000Z",
            updated_at: "2022-02-11T07:00:15.000000Z",
            creator_type: null,
            creator_id: null,
          },
        },
        {
          id: 35,
          chatroom_id: 3,
          customer_id: 29,
          content: "\u5947\u602a",
          created_at: "2022-03-16T09:31:41.000000Z",
          updated_at: "2022-03-16T09:31:41.000000Z",
          customer: {
            id: 29,
            org_id: 2,
            user_id: null,
            merchant_id: 3,
            name: "\u6e2c\u8a66\u4eba1 \ud83c\udf92",
            outer_code: "XXXXXXCCC",
            bundle_code: null,
            bundle_code_type: null,
            mobile_phone: null,
            phone_verified: false,
            email: null,
            avatar_url: null,
            birthday: null,
            sexual: null,
            data: {
              userId: "U6154977cfd6c097a3de815cf72c51b6b",
              displayName: "Paddy \u9ec3\u667a\u5f6c",
              pictureUrl:
                "https://profile.line-scdn.net/0hdYLu4sqSO3ZxDBPdzLFEIU1JNRsGIj0-CW0kFVIMNxUPPi4jRT9xEFZfZkZdO30jT2whRQNbZ0AJ",
            },
            is_friend: false,
            join_datetime: null,
            leave_datetime: "2022-02-11T07:00:15.000000Z",
            created_at: "2021-08-27T10:09:30.000000Z",
            updated_at: "2022-02-11T07:00:15.000000Z",
            creator_type: null,
            creator_id: null,
          },
        },
      ],
      filters: [
        { id: "所有收件匣", icon: "fa fa-inbox" },
        { id: "未指派", icon: "fa fa-clock-o" },
        { id: "完成", icon: "fa fa-check-circle-o" },
        { id: "所有已指派", icon: "fa fa-files-o" },
        { id: "被指派", icon: "fa fa-sign-in" },
        { id: "聊天機器人", icon: "fa fa-android" },
        { id: "垃圾匣", icon: "fa fa-trash-o" },
        { id: "顧客已封鎖", icon: "fa fa-ban" },
      ],
      roomTypeFilters: [
        { value: 1, text: "客戶", variant: "dark" },
        { value: 2, text: "非客戶", variant: "danger" },
        { value: 3, text: "客服", variant: "success" },
      ],
      currentRoomType: null,
      roomStatusFilters: [
        { value: 1, text: "待處理", variant: "danger" },
        { value: 2, text: "處理中", variant: "warning" },
        { value: 3, text: "處理完畢", variant: "primary" },
      ],
      currentRoomStatus: [],
      roomStatusKeyword: null,
      tags: ["買香水", "女性", "20歲", "永和", "嬰兒用品"],
      keyword: null,
      isMessageSearching: false,
      m_pos: null,
      leftWidth: null,
      unread: false,
      message: null,
      bottom: false,
      loading: false,
    };
  },

  created() {},
  components: {
    ChatBox,
  },
  methods: {
    format,
    formatDate(dateString) {
      let now = new Date();

      let date = new Date(dateString);

      let sameYear = now.getFullYear() == date.getFullYear();
      let sameDate =
        now.getMonth() == date.getMonth() && now.getDate() == date.getDate();

      return this.format(
        date,
        `${sameYear ? "" : "yyyy/"}${sameDate ? "" : "MM/dd "}${
          sameYear && sameDate ? "HH:mm" : !sameDate ? "" : "HH:mm"
        }`
      );
    },
    insertSticker(sticker) {
      console.log(sticker);
    },
    insertImage(data) {
      console.log(data);
    },
    insertFile(data) {
      console.log(data);
    },
    checkStrike(messages, idx) {
      if (idx == messages.length - 1) {
        return true;
      } else if (idx + 1 < messages.length) {
        let now = new Date(messages[idx + 1].created_at);
        let date = new Date(messages[idx].created_at);
        return !(
          now.getFullYear() == date.getFullYear() &&
          now.getMonth() == date.getMonth() &&
          now.getDate() == date.getDate()
        );
      } else return false;
    },
    resizeStart(e) {
      this.leftWidth = this.$refs.chatlist.getBoundingClientRect().width;
      this.m_pos = e.clientX;

      document.onselectstart = () => {
        return false;
      };

      document.addEventListener("mousemove", this.resizeMove);
      document.addEventListener("mouseup", this.resizeEnd);
    },
    resizeMove(e) {
      const dx = e.clientX - this.m_pos;

      const newLeftWidth =
        ((this.leftWidth + dx) * 100) /
        this.$refs.chatlist.parentNode.getBoundingClientRect().width;
      this.$refs.chatlist.style.width = `${newLeftWidth}%`;
    },
    resizeEnd() {
      document.removeEventListener("mousemove", this.resizeMove);
      document.onselectstart = () => {
        return true;
      };
    },
    scrollToBottom() {
      this.$refs.messages.scrollTo(0, this.$refs.messages.scrollHeight);
    },
    singleFilterSelect(value) {
      this.currentRoomType = value;
      this.$refs.roomTypeFilter.hide(true);
    },
    multiFilterSelect(value) {
      let els = this.currentRoomStatus;
      if (els.includes(value)) {
        els.splice(els.indexOf(value), 1);
      } else {
        els.push(value);
      }
    },
  },
  watch: {
    currentRoomType(newValue, oldValue) {
      console.log(newValue, oldValue);
    },
    currentRoomStatus(newValue, oldValue) {
      console.log(newValue, oldValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.chatroom {
  height: 80vh;
  .chat-list {
    min-width: 80px;
    max-width: 360px;
    width: 25%;
    overflow-x: hidden;

    .header {
      .search {
        position: relative;

        input {
          padding-left: 45px;
          height: 35px;
        }
        i {
          position: absolute;
          left: 18px;
          top: 10px;
          height: 15px;
        }
      }
      .filter {
        .badge {
          font-size: 14px;
        }

        .item {
          cursor: pointer;
          &:hover {
            background: #3085d7 !important;
          }
        }

        .search {
          input {
            padding-left: 35px;
          }
        }
      }
    }

    .list {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .resizer {
    background-color: #ccc;
    width: 0px;
    position: relative;

    i {
      position: absolute;
      top: 50%;
      padding: 5px;
      cursor: ew-resize;
    }
  }

  .chatbox {
    .message-container {
      overflow-y: auto;

      .message-box {
        margin-right: auto;
        display: block;
        width: 100%;

        .message-wrapper {
          max-width: 80%;
          display: inline-block;

          .message {
            background-color: #f4f6f9;
            border-radius: 1rem;
            border-bottom-left-radius: 0;
          }
        }

        &.owner {
          align-self: flex-end;
          text-align: right;
          margin-left: auto;
          margin-right: 0;

          .message {
            background-color: #2196f3;
            border-radius: 1rem;
            border-bottom-right-radius: 0;
            color: white;
            text-align: left;
          }
        }
      }
    }

    .strike {
      display: flex;
      flex-direction: row;

      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid #ccc;
        margin: auto 5px;
      }
    }

    .unread {
      left: 0;
      right: 10px;
      top: -45px;

      text-align: center;
      opacity: 0.3;
    }
  }
  .profile {
    min-width: 25%;
    width: 25%;
    overflow-y: scroll;

    div[aria-expanded="true"] {
      i::before {
        content: "\f077";
      }
    }
    div[aria-expanded="false"] {
      i::before {
        content: "\f078";
      }
    }
  }
  .handler {
    cursor: ew-resize;
    width: 0;
  }
}
</style>
